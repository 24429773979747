@import url("https://fonts.googleapis.com/css2?family=Maven+Pro&family=Montserrat:ital,wght@0,300;1,300&family=Noto+Sans+Mono:wght@300&family=Permanent+Marker&family=Roboto+Condensed:ital@0;1&family=Titillium+Web:ital,wght@1,300&family=Ubuntu:wght@500&display=swap");

.App {
  text-align: center;
}

.page-background {
  background-color: #2d2d2d;
}

.white-background {
  color: white;
  font-size: 30px;
}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.full-height {
  height: 100vh;
}

a {
  color: white !important;
}

h1 {
  font-size: 70px !important;
  font-family: "Ubuntu", sans-serif;
  color: white;
}

h3 {
  font-size: 31px !important;
  font-family: "Maven Pro", sans-serif;
  color: #72efdd;
}

.center {
  display: flex;
  justify-content: center;
}

.MuiCardActionArea-root:hover {
  background-color: #2d2d2d;
}

.button-design {
  color: rgb(125, 76, 225) !important;
  border-color: rgb(125, 76, 225) !important;
}

.button-design:hover {
  color: rgb(92, 236, 250) !important;
  border-color: rgb(85, 246, 249) !important;
}

.accordionComp {
  width: 900px;
}

.large-spacing-between {
  margin-bottom: 30vh;
}

.image-list-height {
  height: 800px;
}

@media (max-width: 1400px) {
  .header-text {
    text-align: center;
  }

  .button-row {
    justify-content: center;
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto !important;
  }

  .css-o69gx8-MuiCardMedia-root {
    max-height: 100px;
  }

  .logo {
    max-width: 200px;
  }

  #about {
    margin-top: 150px;
    height: 100%;
  }

  .not-overlapping-nav {
    height: 200px !important;
  }

  .image-list-height {
    height: 700px;
  }
}

@media (max-width: 940px) {
  img {
    max-width: 100%;
    width: 100%;
    height: auto !important;
  }
  .logo {
    max-width: 200px;
  }
  .accordionComp {
    width: 100%;
  }

  h1 {
    font-size: 40px !important;
  }

  .chip-col {
    flex-direction: column !important;
  }

  .add-bottom-space-small {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .spacing-between {
    margin-bottom: 30px;
  }

  .large-spacing-between {
    margin-bottom: 200px;
  }

  .button-design {
    margin-bottom: 20px !important;
    width: 100%;
    height: 60px;
  }

  .image-list-height {
    height: 100%;
    width: 100%;
  }

  .MuiBox-root {
    height: 70% !important;
    width: 100% !important;
  }

  /* .MuiImageList-root {
    column-count: 3 !important;
  } */
}
