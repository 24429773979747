.nav-font {
  color: white;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 28px;
}

.nav-link:hover {
  color: #72efdd !important;
}

.nav-color {
  background-color: rgba(0, 0, 0, 0.835);
}
