.accordion-title {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: white;
  margin-bottom: 0;
}

.accordion-body {
  font-weight: 400;
  color: white;
  background-color: #1b1b1b;
  font-size: 19px;
}

.accordion-button:not(.collapsed) {
  background-color: #262525;
  border-color: black;
  color: black;
}

.accordion-button:focus {
  border-color: black;
  box-shadow: 5px 5px 5px rgba(5, 5, 5, 0.903);
}

.accordion-item {
  border: 1px solid black;
}

.accordion {
  --bs-accordion-bg: #1b1b1b;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
